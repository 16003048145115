<template>
    <el-dialog
        title=""
        :visible.sync="visible_inline"
        :append-to-body="true"
        :close-on-click-modal="true"
        width="560px"
    >
        <img
            :src="require('@/assets/images/create_campaign_success.gif')"
            alt=""
            class="mx-auto w-[140px]"
        />

        <div class="mt-5">
            <p
                class="text-lg leading-[1.7rem] text-center font-semibold mt-6 break-word text-center"
            >
                {{ $t('page.campaign.create_campaign_success') }}
            </p>

            <div class="mt-3">
                <p>
                    {{ $t('page.campaign.desc_create_campaign_success') }}
                </p>
                <p>- {{ $t('page.campaign.desc_create_other_campaign') }}</p>
                <p>
                    -
                    {{
                        $t(
                            'page.campaign.desc_create_new_adgroup_from_created_campaign'
                        )
                    }}
                </p>

                <p class="italic mt-2">
                    * {{ $t('page.campaign.skip_return_campaign_list') }}
                </p>

                <p
                    v-if="show_warning_add_balance"
                    class="text-red font-medium mt-1 break-keep"
                >
                    {{ $t('page.campaign.desc_warning_add_balance') }}
                </p>
            </div>

            <div class="flex justify-center mt-5">
                <el-button
                    size="small"
                    class="uppercase w-[80px]"
                    @click="visible_inline = false"
                >
                    {{ $t('button.skip') }}
                </el-button>

                <el-button
                    v-if="show_warning_add_balance"
                    type="success"
                    plain
                    icon="el-icon-money"
                    size="small"
                    @click="
                        $router.push({
                            path: `${p__group_route.ads_manager}/${p__route.payment}?advertiser_id=${advertiser_id}`
                        })
                    "
                >
                    {{ $t('page.payment.add_balance') }}
                </el-button>

                <div class="!ml-auto"></div>

                <el-button type="primary" size="small" @click="handleGoToStep2">
                    {{
                        $t(
                            'page.campaign.create_new_adgroup_from_created_campaign'
                        )
                    }}
                </el-button>

                <el-button type="primary" size="small" @click="$router.go()">
                    {{ $t('page.campaign.create_other_campaign') }}
                </el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    props: [
        'visible',
        'created_campaign_id',
        'advertiser_id',
        'show_warning_add_balance'
    ],

    computed: {
        visible_inline: {
            get() {
                return this.visible
            },
            set(value) {
                this.$emit('update:visible', value)
            }
        }
    },

    watch: {
        visible_inline() {
            if (!this.visible_inline) {
                this.$router.push({
                    path: `${this.p__group_route.ads_manager}/${this.p__route.campaign}?advertiser_id=${this.advertiser_id}`
                })
                // if (this.created_campaign_id) {
                //     const filtering = [
                //         {
                //             filter_value: JSON.stringify([
                //                 this.created_campaign_id
                //             ]),
                //             field_name: 'campaign_ids',
                //             filter_type: 'IN'
                //         }
                //     ]
                //     this.$router.push({
                //         path: `${this.p__group_route.ads_manager}/${this.p__route.campaign}`,
                //         query: { filtering: JSON.stringify(filtering) }
                //     })
                // } else {
                //     this.$router.push({
                //         path: `${this.p__group_route.ads_manager}/${this.p__route.campaign}`
                //     })
                // }
            }
        }
    },

    methods: {
        handleGoToStep2() {
            this.p__event_bus.$emit(
                'eb_create_campaign_go_to_step2_after_created_success'
            )
        }
    }
}
</script>
