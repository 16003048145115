export default {
    props: [
        'step',
        'objective_type',
        'currency',
        'advertiser_id',
        'bc_id',
        'belong_to_atosa',
        'tiktok_account_id'
    ],

    computed: {
        m__step_inline: {
            get() {
                return this.step
            },
            set(value) {
                this.$emit('update:step', value)
            }
        }
    }
}
