var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"component-box flex flex-col space-y-3"},[_c('div',{staticClass:"flex items-center space-x-2",class:[_vm.m__step_inline !== 1 ? 'cursor-pointer ' : ''],on:{"click":function($event){!_vm.created_campaign_id && (_vm.m__step_inline = 1)}}},[_c('el-avatar',{class:[_vm.m__step_inline !== 1 ? '!bg-border ' : ''],attrs:{"size":32}},[(_vm.m__step_inline !== 1)?_c('i',{staticClass:"el-icon-check"}):_c('span',[_vm._v("1")])]),_c('p',{staticClass:"text-sm",class:{ 'font-semibold': _vm.m__step_inline === 1 }},[_vm._v(" "+_vm._s(_vm.$t('common.campaign'))+" ")])],1),_c('div',{staticClass:"flex items-center space-x-2",class:[
            _vm.m__step_inline === 1
                ? 'cursor-not-allowed'
                : _vm.m__step_inline === 3
                ? 'cursor-pointer'
                : ''
        ],on:{"click":function($event){_vm.m__step_inline === 3 && (_vm.m__step_inline = 2)}}},[_c('el-avatar',{class:{ '!bg-border': _vm.m__step_inline !== 2 },attrs:{"size":32}},[(_vm.m__step_inline === 3)?_c('i',{staticClass:"el-icon-check"}):_c('span',[_vm._v("2")])]),_c('p',{staticClass:"text-sm",class:{ 'font-semibold': _vm.m__step_inline === 2 }},[_vm._v(" "+_vm._s(_vm.$t('common.adgroup'))+" ")])],1),_c('div',{staticClass:"flex items-center space-x-2",class:[_vm.m__step_inline !== 3 ? 'cursor-not-allowed' : '']},[_c('el-avatar',{class:{ '!bg-border': _vm.m__step_inline !== 3 },attrs:{"size":32}},[_vm._v("3")]),_c('p',{staticClass:"text-sm",class:{ 'font-semibold': _vm.m__step_inline === 3 }},[_vm._v(" "+_vm._s(_vm.$t('common.ad'))+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }