<template>
    <div class="component-box flex flex-col space-y-3">
        <div
            class="flex items-center space-x-2"
            :class="[m__step_inline !== 1 ? 'cursor-pointer ' : '']"
            @click="!created_campaign_id && (m__step_inline = 1)"
        >
            <el-avatar
                :class="[m__step_inline !== 1 ? '!bg-border ' : '']"
                :size="32"
            >
                <i v-if="m__step_inline !== 1" class="el-icon-check"></i>
                <span v-else>1</span>
            </el-avatar>

            <p
                class="text-sm"
                :class="{ 'font-semibold': m__step_inline === 1 }"
            >
                {{ $t('common.campaign') }}
            </p>
        </div>
        <div
            class="flex items-center space-x-2"
            :class="[
                m__step_inline === 1
                    ? 'cursor-not-allowed'
                    : m__step_inline === 3
                    ? 'cursor-pointer'
                    : ''
            ]"
            @click="m__step_inline === 3 && (m__step_inline = 2)"
        >
            <el-avatar
                :class="{ '!bg-border': m__step_inline !== 2 }"
                :size="32"
            >
                <i v-if="m__step_inline === 3" class="el-icon-check"></i>
                <span v-else>2</span>
            </el-avatar>

            <p
                class="text-sm"
                :class="{ 'font-semibold': m__step_inline === 2 }"
            >
                {{ $t('common.adgroup') }}
            </p>
        </div>
        <div
            class="flex items-center space-x-2"
            :class="[m__step_inline !== 3 ? 'cursor-not-allowed' : '']"
        >
            <el-avatar
                :class="{ '!bg-border': m__step_inline !== 3 }"
                :size="32"
                >3</el-avatar
            >

            <p
                class="text-sm"
                :class="{ 'font-semibold': m__step_inline === 3 }"
            >
                {{ $t('common.ad') }}
            </p>
        </div>
    </div>
</template>

<script>
import createCampaignMixin from '../_mixin'

export default {
    mixins: [createCampaignMixin],

    props: ['created_campaign_id']
}
</script>
