<template>
    <section class="min-w-[800px]">
        <AdvertisingObjective
            :objective_type.sync="objective_type_inline"
            :handle_next_step="handleNextStep"
            :belong_to_atosa="belong_to_atosa"
        ></AdvertisingObjective>

        <div v-if="objective_type_inline" class="component-box mt-4">
            <p class="font-semibold mb-6">
                {{ $t('common.setting') }}
            </p>
            <div>
                <Name
                    :selected_item.sync="infor_campaign_inline"
                    :objective_type="objective_type"
                    :ads_account="ads_account"
                    :tiktok_business="tiktok_business"
                    class="mb-8"
                ></Name>

                <div>
                    <div class="flex flex-col space-y-6">
                        <!-- <div
                v-if="
                    ['VIDEO_VIEWS', 'PRODUCT_SALES'].every(
                        (item) =>
                            item !== objective_type_inline
                    )
                "
            >
                <el-switch
                    v-model="
                        infor_campaign_inline.switch_split_test
                    "
                    :active-text="
                        $t('page.campaign.create_split_test')
                    "
                    :disabled="
                        infor_campaign_inline.switch_camp_budget_optimization
                    "
                ></el-switch>
                <p class="component-text-desc">
                    {{ $t('page.campaign.desc_create_split_test') }}
                    <learn-more-anchor
                        href="https://ads.tiktok.com/help/article?aid=633133124242137021&redirected=1"
                    ></learn-more-anchor>
                </p>
            </div> -->

                        <div
                            v-if="
                                ['PRODUCT_SALES'].every(
                                    (item) => item !== objective_type_inline
                                )
                            "
                        >
                            <el-switch
                                v-model="
                                    infor_campaign_inline.switch_camp_budget_optimization
                                "
                                :active-text="
                                    $t(
                                        'page.campaign.campaign_budget_optimization'
                                    )
                                "
                                :disabled="
                                    infor_campaign_inline.switch_split_test ||
                                    infor_campaign_inline.switch_set_camp_budget
                                "
                            ></el-switch>
                            <p class="component-text-desc">
                                {{
                                    $t(
                                        'page.campaign.desc_campaign_budget_optimization'
                                    )
                                }}
                                <learn-more-anchor
                                    href="https://ads.tiktok.com/help/article/campaign-budget-optimization?redirected=1"
                                ></learn-more-anchor>
                            </p>
                        </div>
                        <div>
                            <el-switch
                                v-model="
                                    infor_campaign_inline.switch_set_camp_budget
                                "
                                :active-text="
                                    $t('page.campaign.set_campaign_budget')
                                "
                                :disabled="
                                    infor_campaign_inline.switch_camp_budget_optimization
                                "
                            ></el-switch>
                            <p class="component-text-desc">
                                {{
                                    $t('page.campaign.desc_set_campaign_budget')
                                }}
                                <learn-more-anchor
                                    href="https://ads.tiktok.com/help/article?aid=6712393859751477254&redirected=1"
                                ></learn-more-anchor>
                            </p>
                        </div>
                    </div>

                    <div
                        v-if="
                            infor_campaign_inline.switch_camp_budget_optimization ||
                            infor_campaign_inline.switch_set_camp_budget
                        "
                        class="mt-4"
                    >
                        <label class="component-label">
                            {{ $t('common.budget') }}
                        </label>
                        <br />
                        <div class="flex space-x-2 w-1/2">
                            <el-select
                                v-model="infor_campaign_inline.budget_mode"
                                size="small"
                                filterable
                                :placeholder="
                                    $t('input.placeholder.please_select')
                                "
                            >
                                <el-option
                                    v-for="item in p__budget_mode_options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                            <el-input
                                v-model="infor_campaign_inline.budget"
                                class="flex-1"
                                size="small"
                                type="number"
                                :placeholder="
                                    $t('input.placeholder.please_input')
                                "
                            >
                                <template slot="append">
                                    {{ currency }}
                                </template>
                            </el-input>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            v-if="objective_type_inline"
            class="component-box sticky bottom-0 top-0 mt-4 flex items-center border-bg border-t-[1px] z-50"
        >
            <div class="flex items-center">
                <el-checkbox
                    v-model="checked_created_show_budget_adgroup_inline"
                >
                    {{ $t('common.manage_ad_group_budgets') }}</el-checkbox
                >
            </div>

            <el-button
                size="small"
                icon="el-icon-right"
                class="ml-auto"
                type="success"
                @click="handleNextStepFaster"
            >
                Tạo nhanh
            </el-button>

            <el-button
                type="primary"
                size="small"
                icon="el-icon-right"
                class="ml-auto"
                @click="handleNextStep"
            >
                {{ $t('button.continue') }}
            </el-button>
        </div>
    </section>
</template>

<script>
import AdvertisingObjective from './advertising_objective'
import createCampaignMixin from '../_mixin'
import Name from './name'

export default {
    components: {
        AdvertisingObjective,
        Name
    },

    mixins: [createCampaignMixin],

    props: [
        'checked_created_show_budget_adgroup',
        'min_budget_campaign',
        'infor_campaign',
        'belong_to_atosa',
        'default_state_infor_campaign',
        'ads_account',
        'tiktok_business'
    ],

    computed: {
        checked_created_show_budget_adgroup_inline: {
            get() {
                return this.checked_created_show_budget_adgroup
            },
            set(value) {
                this.$emit('update:checked_created_show_budget_adgroup', value)
            }
        },

        objective_type_inline: {
            get() {
                return this.objective_type
            },
            set(value) {
                this.$emit('update:objective_type', value)
            }
        },

        infor_campaign_inline: {
            get() {
                return this.infor_campaign
            },
            set(value) {
                this.$emit('update:infor_campaign', value)
            }
        }
    },

    watch: {
        objective_type_inline() {
            this.formatInforCamp()
        },

        'infor_campaign_inline.switch_set_camp_budget'() {
            if (this.infor_campaign_inline.switch_set_camp_budget) {
                this.infor_campaign_inline.budget_mode = 'BUDGET_MODE_DAY'
            } else {
                this.infor_campaign_inline.budget_mode = 'BUDGET_MODE_INFINITE'
            }
        },

        min_budget_campaign() {
            this.infor_campaign_inline.budget = this.min_budget_campaign
        },

        'infor_campaign_inline.budget_mode'() {
            if (
                this.infor_campaign_inline.budget_mode === 'BUDGET_MODE_TOTAL'
            ) {
                this.infor_campaign_inline.budget =
                    this.p__findMinimumDailyBudgetCampaign(this.currency)
            } else {
                this.infor_campaign_inline.budget = this.min_budget_campaign
            }
        }
    },

    mounted() {
        this.formatInforCamp()
    },

    methods: {
        formatInforCamp() {
            Object.keys(this.default_state_infor_campaign).forEach(
                (k) =>
                    (this.infor_campaign_inline[k] =
                        this.default_state_infor_campaign[k])
            )

            this.infor_campaign_inline.objective_type =
                this.objective_type_inline
        },

        handleNextStep() {
            if (!this.infor_campaign_inline.campaign_name) {
                this.p__showNotify(
                    'warning',
                    this.$t('page.campaign.please_input_campaign_name')
                )

                document
                    .getElementById('input_campaign_name_wrapper')
                    .scrollIntoView({ behavior: 'smooth' })
                document.getElementById('input_campaign_name').focus()
                document.getElementById('input_campaign_name').className +=
                    ' border-red border-[1px] rounded-[8px]'

                return
            }

            if (
                this.infor_campaign_inline.budget_mode === 'BUDGET_MODE_TOTAL'
            ) {
                if (
                    this.infor_campaign_inline.budget <
                    this.p__findMinimumDailyBudgetCampaign(this.currency)
                ) {
                    this.p__showNotify(
                        'warning',
                        this.$t('page.campaign.please_budget_than_min', {
                            budget: this.p__renderMoneyByCurrency(
                                this.p__findMinimumDailyBudgetCampaign(
                                    this.currency
                                ),
                                this.currency
                            )
                        })
                    )

                    return
                }
            } else {
                if (
                    this.infor_campaign_inline.budget < this.min_budget_campaign
                ) {
                    this.p__showNotify(
                        'warning',
                        this.$t('page.campaign.please_budget_than_min', {
                            budget: this.p__renderMoneyByCurrency(
                                this.min_budget_campaign,
                                this.currency
                            )
                        })
                    )

                    return
                }
            }

            this.m__step_inline = 2
        },

        handleNextStepFaster() {
            if (!this.infor_campaign_inline.campaign_name) {
                this.p__showNotify(
                    'warning',
                    this.$t('page.campaign.please_input_campaign_name')
                )

                document
                    .getElementById('input_campaign_name_wrapper')
                    .scrollIntoView({ behavior: 'smooth' })
                document.getElementById('input_campaign_name').focus()
                document.getElementById('input_campaign_name').className +=
                    ' border-red border-[1px] rounded-[8px]'

                return
            }

            if (
                this.infor_campaign_inline.budget_mode === 'BUDGET_MODE_TOTAL'
            ) {
                if (
                    this.infor_campaign_inline.budget <
                    this.p__findMinimumDailyBudgetCampaign(this.currency)
                ) {
                    this.p__showNotify(
                        'warning',
                        this.$t('page.campaign.please_budget_than_min', {
                            budget: this.p__renderMoneyByCurrency(
                                this.p__findMinimumDailyBudgetCampaign(
                                    this.currency
                                ),
                                this.currency
                            )
                        })
                    )

                    return
                }
            } else {
                if (
                    this.infor_campaign_inline.budget < this.min_budget_campaign
                ) {
                    this.p__showNotify(
                        'warning',
                        this.$t('page.campaign.please_budget_than_min', {
                            budget: this.p__renderMoneyByCurrency(
                                this.min_budget_campaign,
                                this.currency
                            )
                        })
                    )

                    return
                }
            }

            this.m__step_inline = 2.1
        }
    }
}
</script>
