import request from './_config'

const path = `campaigns`

export function getCampaigns(tiktok_account_id, params = null) {
    return request({
        url: `${path}/${tiktok_account_id}`,
        method: 'get',
        params
    })
}

export function updateCampaignStatus(tiktok_account_id, data) {
    return request({
        url: `${path}/status/${tiktok_account_id}`,
        method: 'post',
        data: {
            type: data.type,
            advertiser_id: data.advertiser_id,
            campaign_ids: data.campaign_ids,
            belong_to_atosa: data.belong_to_atosa,
            operation_status: data.operation_status
        }
    })
}

export function updateCampaign(tiktok_account_id, data) {
    return request({
        url: `${path}/${tiktok_account_id}`,
        method: 'put',
        data: {
            ...data,
            advertiser_id: data.advertiser_id,
            campaign_id: data.campaign_id
        }
    })
}

export function createCampaign(tiktok_account_id, data) {
    return request({
        url: `${path}/${tiktok_account_id}`,
        method: 'post',
        data: {
            operation_status: 'ENABLE',
            belong_to_atosa: data.belong_to_atosa,
            advertiser_id: data.advertiser_id,
            campaign_name: data.campaign_name,
            objective_type: data.objective_type,
            budget_mode: data.budget_mode,
            budget: data.budget,
            campaign_type: data.campaign_type,
            app_promotion_type: data.app_promotion_type,
            special_industries: data.special_industries,
            rf_campaign_type: data.rf_campaign_type
        }
    })
}

export function createFullCampaign(tiktok_account_id, data) {
    return request({
        url: `${path}/full/${tiktok_account_id}`,
        method: 'post',
        data
    })
}

export function createFullAdgroup(tiktok_account_id, data) {
    return request({
        url: `${path}/adgroup/full/${tiktok_account_id}`,
        method: 'post',
        data
    })
}

export function createTemplateProductSaleCampaign(data) {
    return request({
        url: `${path}/template/product-sale`,
        method: 'post',
        data
    })
}
