<template>
    <div v-if="selected_item_in_line" id="input_campaign_name_wrapper">
        <label class="component-label">
            {{ $t('page.campaign.campaign_name') }}
        </label>

        <div class="flex space-x-2">
            <el-tooltip
                class="item"
                effect="dark"
                :content="preview_name"
                placement="top"
            >
                <el-tag
                    v-if="selected_template_name"
                    effect="plain"
                    class="flex items-center"
                    closable
                    @close="selected_template_name = null"
                >
                    <p class="truncate max-w-[160px]">{{ preview_name }}</p>
                </el-tag>
            </el-tooltip>

            <el-input
                id="input_campaign_name"
                v-model="campaign_name"
                class="w-1/2"
                size="small"
            ></el-input>

            <div>
                <el-button
                    v-if="template_name_list.length === 0"
                    icon="el-icon-postcard"
                    @click="show_create_dialog = true"
                    size="small"
                >
                    {{ $t('common.name_template') }}
                </el-button>

                <el-dropdown v-else trigger="click">
                    <el-button icon="el-icon-postcard" size="small">
                        {{ $t('common.name_template') }}
                    </el-button>
                    <el-dropdown-menu
                        slot="dropdown"
                        class="overflow-auto max-h-[280px]"
                    >
                        <el-dropdown-item
                            v-for="item in template_name_list"
                            :key="item._id"
                            class="relative"
                        >
                            <div
                                @click="handleSelected(item)"
                                class="py-2 pr-8"
                            >
                                <p class="leading-5 text-sm">
                                    {{ item.config_name }}
                                </p>
                                <p
                                    class="text-desc-text truncate max-w-[200px] leading-5 text-xs"
                                >
                                    {{ genDescriptionTemplateName(item) }}
                                </p>
                            </div>

                            <div
                                class="absolute top-1/2 -translate-y-1/2 right-2 flex space-x-1"
                            >
                                <i
                                    class="el-icon-edit-outline text-base"
                                    @click="handleEdit(item)"
                                ></i>
                                <i
                                    class="el-icon-delete text-base"
                                    @click="handleRemove(item._id)"
                                ></i>
                            </div>
                        </el-dropdown-item>
                        <el-dropdown-item divided
                            ><div @click="show_create_dialog = true">
                                <i class="el-icon-plus"></i>
                                {{ $t('button.create') }}
                            </div>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>

        <p
            v-if="selected_template_name"
            class="text-xs text-desc-text mt-2"
        >
            {{ $t('common.preview') }} : {{ selected_item.campaign_name }}
        </p>

        <CreateTemplateNameDialog
            :visible.sync="show_create_dialog"
            :field_options="field_options"
            :genPreviewName="genPreviewName"
            :getTemplateNames="getTemplateNames"
        ></CreateTemplateNameDialog>

        <EditTemplateNameDialog
            :visible.sync="show_edit_dialog"
            :field_options="field_options"
            :selected_item="selected_edit_item"
            :genPreviewName="genPreviewName"
            :getTemplateNames="getTemplateNames"
        ></EditTemplateNameDialog>
    </div>
</template>

<script>
import CreateTemplateNameDialog from './create-template-name-dialog'
import EditTemplateNameDialog from './edit-template-name-dialog'
import {
    getTemplateNames,
    deleteTemplateName
} from '@/services/atosa-tiktok-ads/tool'
import moment from 'moment'

export default {
    components: {
        CreateTemplateNameDialog,
        EditTemplateNameDialog
    },

    props: [
        'selected_item',
        'objective_type',
        'ads_account',
        'tiktok_business'
    ],

    data() {
        return {
            selected_edit_item: null,
            campaign_name: '',
            show_create_dialog: false,
            show_edit_dialog: false,
            selected_template_name: null,
            template_name_list: [],
            field_options: [
                {
                    label: this.$t('common.objective_type'),
                    key: 'objective_type'
                },
                {
                    label: this.$t('common.advertiser_account'),
                    key: 'advertiser_account'
                },
                {
                    label: this.$t('common.tiktok_business'),
                    key: 'tiktok_business'
                },
                {
                    label: this.$t('common.budget'),
                    key: 'budget'
                },
                {
                    label: this.$t('common.time'),
                    key: 'time'
                },
                {
                    label: this.$t('common.text'),
                    key: 'text'
                }
            ]
        }
    },

    computed: {
        selected_item_in_line: {
            get: function () {
                return this.selected_item
            },
            set: function (val) {
                this.$emit('update:selected_item', val)
            }
        },

        preview_name() {
            if (this.selected_template_name) {
                return this.genPreviewName(
                    this.selected_template_name.fields,
                    this.selected_template_name.field_separator
                )
            }

            return ''
        }
    },

    watch: {
        preview_name() {
            this.selected_item_in_line.campaign_name =
                this.preview_name + ' ' + this.campaign_name
        },

        campaign_name() {
            if (this.selected_template_name) {
                this.selected_item_in_line.campaign_name =
                    this.preview_name + ' ' + this.campaign_name
            } else {
                this.selected_item_in_line.campaign_name = this.campaign_name
            }
        },

        objective_type() {
            if (!this.selected_template_name) {
                const date = moment().format('HH:mm DD-MM-YYYY')

                this.selected_item_in_line.campaign_name = `${this.objective_type}_${date}`
                this.campaign_name = this.selected_item_in_line.campaign_name
            }
        }
    },

    mounted() {
        this.getTemplateNames()

        const date = moment().format('HH:mm DD-MM-YYYY')

        this.selected_item_in_line.campaign_name = `${this.objective_type}_${date}`
        this.campaign_name = this.selected_item_in_line.campaign_name
    },

    methods: {
        handleEdit(item) {
            this.selected_edit_item = item

            this.show_edit_dialog = true
        },

        async handleRemove(id) {
            try {
                await this.$confirm(
                    this.$t('common.delete_data_confirm'),
                    this.$t('common.warning'),
                    {
                        confirmButtonText: this.$t('button.confirm'),
                        cancelButtonText: this.$t('button.close'),
                        type: 'warning'
                    }
                )
            } catch (e) {
                return
            }

            try {
                const response = await deleteTemplateName(id)
                if (response.code === 0) {
                    this.getTemplateNames()
                }
            } catch (error) {
                console.error(error)
            }
        },

        async getTemplateNames() {
            try {
                const response = await getTemplateNames()
                if (response.code === 0) {
                    this.template_name_list = response.data.filter(
                        (item) => item.type === 'campaign'
                    )
                }
            } catch (error) {
                console.error(error)
            }
        },

        genDescriptionTemplateName(item) {
            const temp = item.fields.map((item) => {
                const temp = this.field_options.find(
                    (it) => it.key === item.key
                )

                if (temp) {
                    return temp.label
                }

                return item.key
            })
            return temp.join(` ${item.field_separator} `)
        },

        handleSelected(item) {
            this.selected_template_name = item
        },

        genPreviewName(fields, field_separator) {
            const temp = fields.map((item) => {
                const { key, value } = item

                if (key === 'time') {
                    const date = moment().format(value)
                    return date
                }

                if (key === 'objective_type') {
                    return this.objective_type
                }

                if (key === 'budget') {
                    return this.p__formatBudgetMode(
                        this.selected_item_in_line.budget_mode
                    )
                }

                if (key === 'advertiser_account') {
                    return this.ads_account.name
                }

                if (key === 'tiktok_business') {
                    return this.tiktok_business.user_name
                }

                if (key === 'text') {
                    return value
                }

                return item.key
            })

            return temp.join(`${field_separator}`)
        }
    }
}
</script>
