var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"min-w-[800px]"},[_c('AdvertisingObjective',{attrs:{"objective_type":_vm.objective_type_inline,"handle_next_step":_vm.handleNextStep,"belong_to_atosa":_vm.belong_to_atosa},on:{"update:objective_type":function($event){_vm.objective_type_inline=$event}}}),(_vm.objective_type_inline)?_c('div',{staticClass:"component-box mt-4"},[_c('p',{staticClass:"font-semibold mb-6"},[_vm._v(" "+_vm._s(_vm.$t('common.setting'))+" ")]),_c('div',[_c('Name',{staticClass:"mb-8",attrs:{"selected_item":_vm.infor_campaign_inline,"objective_type":_vm.objective_type,"ads_account":_vm.ads_account,"tiktok_business":_vm.tiktok_business},on:{"update:selected_item":function($event){_vm.infor_campaign_inline=$event}}}),_c('div',[_c('div',{staticClass:"flex flex-col space-y-6"},[(
                            ['PRODUCT_SALES'].every(
                                (item) => item !== _vm.objective_type_inline
                            )
                        )?_c('div',[_c('el-switch',{attrs:{"active-text":_vm.$t(
                                    'page.campaign.campaign_budget_optimization'
                                ),"disabled":_vm.infor_campaign_inline.switch_split_test ||
                                _vm.infor_campaign_inline.switch_set_camp_budget},model:{value:(
                                _vm.infor_campaign_inline.switch_camp_budget_optimization
                            ),callback:function ($$v) {_vm.$set(_vm.infor_campaign_inline, "switch_camp_budget_optimization", $$v)},expression:"\n                                infor_campaign_inline.switch_camp_budget_optimization\n                            "}}),_c('p',{staticClass:"component-text-desc"},[_vm._v(" "+_vm._s(_vm.$t( 'page.campaign.desc_campaign_budget_optimization' ))+" "),_c('learn-more-anchor',{attrs:{"href":"https://ads.tiktok.com/help/article/campaign-budget-optimization?redirected=1"}})],1)],1):_vm._e(),_c('div',[_c('el-switch',{attrs:{"active-text":_vm.$t('page.campaign.set_campaign_budget'),"disabled":_vm.infor_campaign_inline.switch_camp_budget_optimization},model:{value:(
                                _vm.infor_campaign_inline.switch_set_camp_budget
                            ),callback:function ($$v) {_vm.$set(_vm.infor_campaign_inline, "switch_set_camp_budget", $$v)},expression:"\n                                infor_campaign_inline.switch_set_camp_budget\n                            "}}),_c('p',{staticClass:"component-text-desc"},[_vm._v(" "+_vm._s(_vm.$t('page.campaign.desc_set_campaign_budget'))+" "),_c('learn-more-anchor',{attrs:{"href":"https://ads.tiktok.com/help/article?aid=6712393859751477254&redirected=1"}})],1)],1)]),(
                        _vm.infor_campaign_inline.switch_camp_budget_optimization ||
                        _vm.infor_campaign_inline.switch_set_camp_budget
                    )?_c('div',{staticClass:"mt-4"},[_c('label',{staticClass:"component-label"},[_vm._v(" "+_vm._s(_vm.$t('common.budget'))+" ")]),_c('br'),_c('div',{staticClass:"flex space-x-2 w-1/2"},[_c('el-select',{attrs:{"size":"small","filterable":"","placeholder":_vm.$t('input.placeholder.please_select')},model:{value:(_vm.infor_campaign_inline.budget_mode),callback:function ($$v) {_vm.$set(_vm.infor_campaign_inline, "budget_mode", $$v)},expression:"infor_campaign_inline.budget_mode"}},_vm._l((_vm.p__budget_mode_options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1),_c('el-input',{staticClass:"flex-1",attrs:{"size":"small","type":"number","placeholder":_vm.$t('input.placeholder.please_input')},model:{value:(_vm.infor_campaign_inline.budget),callback:function ($$v) {_vm.$set(_vm.infor_campaign_inline, "budget", $$v)},expression:"infor_campaign_inline.budget"}},[_c('template',{slot:"append"},[_vm._v(" "+_vm._s(_vm.currency)+" ")])],2)],1)]):_vm._e()])],1)]):_vm._e(),(_vm.objective_type_inline)?_c('div',{staticClass:"component-box sticky bottom-0 top-0 mt-4 flex items-center border-bg border-t-[1px] z-50"},[_c('div',{staticClass:"flex items-center"},[_c('el-checkbox',{model:{value:(_vm.checked_created_show_budget_adgroup_inline),callback:function ($$v) {_vm.checked_created_show_budget_adgroup_inline=$$v},expression:"checked_created_show_budget_adgroup_inline"}},[_vm._v(" "+_vm._s(_vm.$t('common.manage_ad_group_budgets')))])],1),_c('el-button',{staticClass:"ml-auto",attrs:{"size":"small","icon":"el-icon-right","type":"success"},on:{"click":_vm.handleNextStepFaster}},[_vm._v(" Tạo nhanh ")]),_c('el-button',{staticClass:"ml-auto",attrs:{"type":"primary","size":"small","icon":"el-icon-right"},on:{"click":_vm.handleNextStep}},[_vm._v(" "+_vm._s(_vm.$t('button.continue'))+" ")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }