<template>
    <div v-loading.fullscreen.lock="m__loading" class="w-[800px]">
        <div class="component-box flex space-x-4">
            <div>
                <p class="component-label">Ngân sách chiến dịch hằng ngày</p>

                <el-input
                    v-model="campaign_budget"
                    class="flex-1"
                    size="small"
                    type="number"
                    :placeholder="$t('input.placeholder.please_input')"
                >
                    <template slot="append">
                        {{ currency }}
                    </template>
                </el-input>
            </div>

            <div>
                <p
                    v-if="
                        selected_item &&
                        selected_item.shopping_ads_type === 'LIVE'
                    "
                    class="component-label"
                >
                    {{
                        $t('page.campaign.desc_schedule_from_end_shopping_live')
                    }}
                </p>
                <p v-else class="component-label">
                    {{ $t('page.campaign.desc_schedule_from_end') }}
                </p>

                <div class="flex items-center space-x-4">
                    <el-date-picker
                        v-model="schedule_start_time"
                        type="datetime"
                        placeholder="Select date and time"
                        size="small"
                        :format="
                            $t('common.format_value_date_picker_with_time')
                        "
                        :picker-options="picker_options_for_now"
                        @change="handleChangeScheduleStartDate"
                    >
                    </el-date-picker>

                    <span>-</span>

                    <el-date-picker
                        v-model="schedule_end_time"
                        type="datetime"
                        placeholder="Select date and time"
                        size="small"
                        :format="
                            $t('common.format_value_date_picker_with_time')
                        "
                        :picker-options="picker_options_for_now"
                        @change="handleChangeScheduleEndDate"
                    >
                    </el-date-picker>
                </div>
            </div>
        </div>

        <ShopSettings
            v-if="selected_item"
            class="mt-4"
            :selected_item.sync="selected_item"
            :tiktok_account_id="tiktok_account_id"
            :belong_to_atosa="belong_to_atosa"
            :type_form="'create'"
            :advertiser_id="advertiser_id"
        ></ShopSettings>

        <div
            v-if="selected_item && selected_item.shopping_ads_type"
            class="mt-4 component-box"
        >
            <p class="font-semibold mb-4">Chi tiết quảng cáo</p>

            <div v-if="selected_item.shopping_ads_type === 'VIDEO'">
                <p class="component-label">Sản phẩm</p>

                <div
                    v-if="product_list.length"
                    class="overflow-auto pr-2 max-h-[280px] flex flex-col space-y-2 mb-4"
                >
                    <draggable
                        v-model="product_list"
                        :options="{ handle: '.drag-handle' }"
                        class="flex gap-1 flex-wrap flex-col"
                    >
                        <div
                            v-for="item in product_list"
                            :key="item.item_group_id"
                            class="bg-bg flex rounded-lg p-3 space-x-3 w-full drag-item drag-handle"
                        >
                            <p class="cursor-pointer font-semibold">::</p>

                            <img
                                :src="item.image_url"
                                class="w-[60px] h-[60px] object-contain"
                            />

                            <div>
                                <el-tooltip
                                    class="item"
                                    effect="dark"
                                    :content="item.title"
                                    placement="top"
                                >
                                    <p
                                        class="truncate font-semibold w-[280px] text-sm"
                                    >
                                        {{ item.title }}
                                    </p>
                                </el-tooltip>

                                <p class="text-desc-text text-xs mt-1">
                                    SPU ID : {{ item.item_group_id }}
                                </p>

                                <p class="mt-1 text-xs">
                                    {{
                                        showPrice(
                                            item.price_list,
                                            item.price.currency
                                        )
                                    }}
                                </p>
                            </div>

                            <el-button
                                class="!ml-auto self-center"
                                type="text"
                                @click="handleRemoveProduct(item.item_group_id)"
                            >
                                {{ $t('common.delete') }}</el-button
                            >
                        </div>
                    </draggable>
                </div>

                <el-button
                    icon="el-icon-plus"
                    size="small"
                    @click="is_add_product_drawer_displayed = true"
                >
                    {{ $t('page.campaign.add_products') }}
                </el-button>

                <p class="component-label mt-4">Video</p>

                <div v-if="video_list">
                    <div
                        v-for="item in video_list"
                        :key="item.item_id"
                        class="bg-bg flex rounded mb-4"
                    >
                        <div
                            class="min-w-[72px] w-[72px] h-[72px] bg-bg rounded-xl overflow-hidden relative cursor-pointer"
                        >
                            <img
                                v-if="item.video_info.poster_url"
                                :src="item.video_info.poster_url"
                                class="object-contain w-full h-full"
                                alt=""
                            />

                            <div
                                class="top-1/2 left-1/2 absolute -translate-x-1/2 -translate-y-1/2"
                            >
                                <i
                                    class="el-icon-caret-right text-white text-xl"
                                ></i>
                            </div>
                        </div>

                        <div class="flex flex-col justify-center space-y-1">
                            <div class="flex items-end space-x-2">
                                <div v-if="item.text">
                                    <el-tooltip
                                        class="item"
                                        effect="dark"
                                        :content="item.text"
                                        placement="top"
                                    >
                                        <p
                                            class="text-xs truncate max-w-[240px]"
                                        >
                                            {{ item.text }}
                                        </p>
                                    </el-tooltip>
                                </div>

                                <span class="text-xs text-desc-text">
                                    {{
                                        p__renderDurationToMinute(
                                            item.video_info.duration
                                        )
                                    }}
                                    |
                                    {{ item.video_info.width }}
                                    x
                                    {{ item.video_info.height }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <el-button
                    icon="el-icon-plus"
                    size="small"
                    @click="is_from_tiktok_post_displayed = true"
                >
                    {{ $t('page.campaign.tiktok_post') }}
                </el-button>
            </div>

            <div
                v-else-if="
                    selected_item.shopping_ads_type === 'PRODUCT_SHOPPING_ADS'
                "
            >
                <p class="component-label">Sản phẩm</p>

                <div
                    v-if="product_list.length"
                    class="overflow-auto pr-2 max-h-[280px] flex flex-col space-y-2 mb-4"
                >
                    <draggable
                        v-model="product_list"
                        :options="{ handle: '.drag-handle' }"
                        class="flex gap-1 flex-wrap flex-col"
                    >
                        <div
                            v-for="item in product_list"
                            :key="item.item_group_id"
                            class="bg-bg flex rounded-lg p-3 space-x-3 w-full drag-item drag-handle"
                        >
                            <p class="cursor-pointer font-semibold">::</p>

                            <img
                                :src="item.image_url"
                                class="w-[60px] h-[60px] object-contain"
                            />

                            <div>
                                <el-tooltip
                                    class="item"
                                    effect="dark"
                                    :content="item.title"
                                    placement="top"
                                >
                                    <p
                                        class="truncate font-semibold w-[280px] text-sm"
                                    >
                                        {{ item.title }}
                                    </p>
                                </el-tooltip>

                                <p class="text-desc-text text-xs mt-1">
                                    SPU ID : {{ item.item_group_id }}
                                </p>

                                <p class="mt-1 text-xs">
                                    {{
                                        showPrice(
                                            item.price_list,
                                            item.price.currency
                                        )
                                    }}
                                </p>
                            </div>

                            <el-button
                                class="!ml-auto self-center"
                                type="text"
                                @click="handleRemoveProduct(item.item_group_id)"
                            >
                                {{ $t('common.delete') }}</el-button
                            >
                        </div>
                    </draggable>
                </div>

                <el-button
                    icon="el-icon-plus"
                    size="small"
                    @click="is_add_product_drawer_displayed = true"
                >
                    {{ $t('page.campaign.add_products') }}
                </el-button>
            </div>

            <div v-else-if="selected_item.shopping_ads_type === 'LIVE'">
                <div
                    v-if="selected_item.extension.identity"
                    class="flex items-center text-xs"
                >
                    <p>
                        {{ $t('page.campaign.post_delivered') }}
                    </p>

                    <div
                        class="flex items-center ml-2 space-x-1 bg-bg rounded-xl pr-2"
                    >
                        <el-avatar
                            :size="21"
                            :src="selected_item.extension.identity.image_uri"
                        ></el-avatar>
                        <p>
                            {{ selected_item.extension.identity.display_name }}
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="component-box mt-4">
            <div class="flex">
                <el-button
                    size="small"
                    class="mr-auto"
                    @click="m__step_inline = 1"
                >
                    {{ $t('button.back') }}
                </el-button>

                <el-button
                    :loading="m__loading"
                    icon="el-icon-plus"
                    size="small"
                    type="primary"
                    class="ml-auto"
                    @click="handleCreate"
                >
                    Tạo nhanh
                </el-button>
            </div>
        </div>

        <AddProductsDrawer
            v-if="
                selected_item &&
                selected_item.store_id &&
                selected_item.shopping_ads_type
            "
            :visible.sync="is_add_product_drawer_displayed"
            :tiktok_account_id="tiktok_account_id"
            :selected_products="product_list"
            :advertiser_id="advertiser_id"
            :belong_to_atosa="belong_to_atosa"
            :catalog_id="selected_item.catalog_id"
            :currency="currency"
            :bc_id="bc_id"
            :store_id="selected_item.store_id"
            :store_authorized_bc_id="selected_item.store_authorized_bc_id"
            :max_products="
                selected_item.shopping_ads_type === 'PRODUCT_SHOPPING_ADS'
                    ? p__number_max_products_in_product_shopping_ads
                    : p__number_max_products_in_video_shopping_ads
            "
            :product_source="selected_item.product_source"
        ></AddProductsDrawer>

        <FromTiktokPostDrawer
            v-if="selected_item && selected_item.identity_id"
            :visible.sync="is_from_tiktok_post_displayed"
            :tiktok_account_id="tiktok_account_id"
            :advertiser_id="advertiser_id"
            :belong_to_atosa="belong_to_atosa"
            :identity_id="selected_item.identity_id"
            :identity_type="selected_item.identity_type"
            :identity_authorized_bc_id="selected_item.identity_authorized_bc_id"
            @choose_video_from_tiktok_post="handleChooseVideoFromTiktokPost"
        ></FromTiktokPostDrawer>

        <CreateSuccessDialog
            :visible.sync="show_create_success_dialog"
            :advertiser_id="advertiser_id"
            :show_warning_add_balance="false"
        ></CreateSuccessDialog>
    </div>
</template>

<script>
import ShopSettings from './shop-settings'
import AddProductsDrawer from './add-products-drawer'
import draggable from 'vuedraggable'
import FromTiktokPostDrawer from './from-tiktok-post-drawer'
import moment from 'moment'
import {
    createFullCampaign,
    createTemplateProductSaleCampaign
} from '@/services/atosa-tiktok-ads/campaign'
import CreateSuccessDialog from './create-success-dialog'
import createCampaignMixin from '../_mixin'

export default {
    components: {
        CreateSuccessDialog,
        ShopSettings,
        AddProductsDrawer,
        FromTiktokPostDrawer,
        draggable
    },

    mixins: [createCampaignMixin],

    props: [
        'min_budget_adgroup',
        'belong_to_atosa',
        'infor_adgroups',
        'advertiser_id',
        'currency',
        'timezone',
        'tiktok_account_id',
        'bc_id',
        'objective_type'
    ],

    data() {
        return {
            show_create_success_dialog: false,
            selected_products: [],
            is_from_tiktok_post_displayed: false,
            is_add_product_drawer_displayed: false,
            selected_item: null,
            campaign_budget: 500000,
            product_list: [],
            video_list: [],
            picker_options_for_now: {
                disabledDate: (date) => this.disabledDateForNow(date)
            },
            schedule_start_time: '',
            schedule_end_time: ''
        }
    },

    computed: {
        infor_adgroups_inline: {
            get() {
                return this.infor_adgroups
            },
            set(value) {
                this.$emit('update:infor_adgroups', value)
            }
        }
    },

    watch: {
        'selected_item.store_id'() {
            this.product_list = []
        },

        'selected_item.identity_id'() {
            this.video_list = []
        }
    },

    mounted() {
        this.schedule_start_time = moment()
            .add(20, 'minutes')
            .format('YYYY-MM-DD HH:mm')
        this.schedule_end_time = moment()
            .add(140, 'minutes')
            .format('YYYY-MM-DD HH:mm')

        this.selected_item = this.infor_adgroups_inline[0]

        this.p__event_bus.$on('eb_select_products', this.handleSelectedProduct)
    },

    destroyed() {
        this.p__event_bus.$off('eb_select_products', this.handleSelectedProduct)
    },

    methods: {
        handleSelectedProduct(products) {
            this.product_list = products
        },

        showPrice(price_list, currency) {
            if (price_list.length === 0) {
                return this.p__renderMoneyByCurrency(0, currency)
            }

            if (price_list.length === 1) {
                return this.p__renderMoneyByCurrency(price_list[0], currency)
            }

            const min = Math.min(...price_list)
            const max = Math.max(...price_list)
            if (min === max) {
                return this.p__renderMoneyByCurrency(min, currency)
            }
            return `${this.p__renderMoneyByCurrency(
                min,
                currency
            )} - ${this.p__renderMoneyByCurrency(max, currency)}`
        },

        handleChooseVideoFromTiktokPost(info_videos) {
            this.video_list = [...info_videos, ...this.video_list]
            console.log(this.video_list)
        },

        disabledDateForNow(date) {
            return moment(date) <= moment().subtract(1, 'days')
        },

        handleChangeScheduleStartDate(val) {
            this.schedule_start_time = moment(val).format('YYYY-MM-DD HH:mm')
        },

        handleChangeScheduleEndDate(val) {
            this.schedule_end_time = moment(val).format('YYYY-MM-DD HH:mm')
        },

        handleRemoveProduct(item_group_id) {
            this.product_list = this.product_list.filter(
                (product) => product.item_group_id !== item_group_id
            )
        },

        async handleCreate() {
            let number_agroups = 1

            if (this.campaign_budget < 150000) {
                return this.p__showNotify(
                    'error',
                    'Ngân sách trong kế hoạch điều chỉnh ngân sách phải lớn hoặc bằng ' +
                        this.p__renderMoneyByCurrency(
                            this.min_budget_adgroup,
                            this.currency
                        )
                )
            } else if (this.campaign_budget < 500000) {
                number_agroups = 2
            } else {
                number_agroups = 3
            }

            const response = await createTemplateProductSaleCampaign({
                campaign_budget: +this.campaign_budget,
                number_agroups
            })

            const budget_list = response.data

            let has_success = false

            this.m__loading = true

            try {
                const current = moment().format('YYYY-MM-DD HH:mm')
                const campaign_name = `PRODUCT_SALES_${current}`
                const adgroup_name = `Adgroup_${current}`
                const ad_name = `Ad_${current}`

                const adgroups = budget_list.map((bud) => {
                    let extend_ad_group = {}
                    let creatives = [
                        {
                            ad_format: 'LIVE_CONTENT',
                            ad_name,
                            ad_text: 'live shopping',
                            creative_type: 'DIRECT_LIVE',
                            dynamic_destination: 'UNSET',
                            dynamic_format: 'UNSET',
                            external_action: 'ACTIVE',
                            fallback_type: 'UNSET',
                            identity_id: this.selected_item.identity_id,
                            identity_type: this.selected_item.identity_type,
                            identity_authorized_bc_id:
                                this.selected_item.identity_authorized_bc_id,
                            is_aco: false,
                            is_creative_authorized: false,
                            is_new_structure: true,
                            operation_status: 'ENABLE',
                            product_specific_type: 'PRODUCT_SET',
                            vast_moat_enabled: false,
                            vertical_video_strategy: 'LIVE_STREAM'
                        }
                    ]

                    if (
                        this.selected_item.shopping_ads_type ===
                        'PRODUCT_SHOPPING_ADS'
                    ) {
                        const item_group_ids = this.product_list.map(
                            (item) => item.item_group_id
                        )

                        extend_ad_group = {
                            product_source: 'STORE',
                            video_download_disabled: true,
                            comment_disabled: true
                        }

                        creatives = [
                            {
                                ad_name,
                                product_specific_type: 'CUSTOMIZED_PRODUCTS',
                                item_group_ids
                            }
                        ]
                    } else if (
                        this.selected_item.shopping_ads_type === 'VIDEO'
                    ) {
                        const item_group_ids = this.product_list.map(
                            (item) => item.item_group_id
                        )

                        extend_ad_group = {
                            product_source: 'STORE',
                            video_download_disabled: true,
                            comment_disabled: true
                        }

                        creatives = this.video_list.map((video) => {
                            return {
                                ad_format: 'SINGLE_VIDEO',
                                ad_name,
                                ad_text: video.text,
                                call_to_action: 'SHOP_NOW',
                                catalog_id: this.selected_item.catalog_id,
                                creative_type: 'SHOP_PDP',
                                dynamic_destination: 'UNSET',
                                dynamic_format: 'UNSET',
                                external_action: 'ACTIVE',
                                fallback_type: 'UNSET',
                                identity_id: this.selected_item.identity_id,
                                identity_type: this.selected_item.identity_type,
                                identity_authorized_bc_id:
                                    this.selected_item
                                        .identity_authorized_bc_id,
                                is_aco: false,
                                is_creative_authorized: false,
                                is_new_structure: true,
                                item_group_ids,
                                operation_status: 'ENABLE',
                                product_specific_type: 'PRODUCT_SET',
                                vast_moat_enabled: false,
                                vertical_video_strategy: 'SINGLE_VIDEO',
                                tiktok_item_id: video.item_id
                            }
                        })
                    }

                    return {
                        adgroup_name,
                        age_groups: [
                            'AGE_18_24',
                            'AGE_25_34',
                            'AGE_35_44',
                            'AGE_45_54',
                            'AGE_55_100'
                        ],
                        bid_display_mode: 'CPMV',
                        bid_price: 1,
                        bid_type: 'BID_TYPE_NO_BID',
                        billing_event: 'CPC',
                        brand_safety_type: 'NO_BRAND_SAFETY',
                        budget: bud.toFixed(0),
                        budget_mode: 'BUDGET_MODE_DAY',
                        catalog_id: this.selected_item.catalog_id,
                        category_id: '0',
                        comment_disabled: false,
                        conversion_bid_price: 0,
                        creative_material_mode: 'CUSTOM',
                        dayparting:
                            '111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111',
                        deep_cpa_bid: 0,
                        frequency: 3,
                        frequency_schedule: 7,
                        gender: 'GENDER_UNLIMITED',
                        inventory_filter_enabled: false,
                        ios14_quota_type: 'UNOCCUPIED',
                        is_hfss: false,
                        is_new_structure: true,
                        identity_id: this.selected_item.identity_id,
                        location_ids: ['1562822'],
                        operation_status: 'ENABLE',
                        optimization_goal: 'CLICK',
                        pacing: 'PACING_MODE_SMOOTH',
                        placement_type: 'PLACEMENT_TYPE_NORMAL',
                        placements: ['PLACEMENT_TIKTOK'],
                        promotion_type: this.selected_item.promotion_type,
                        promotion_target_type: 'UNSET',
                        schedule_start_time: this.schedule_start_time,
                        schedule_end_time: this.schedule_end_time,
                        schedule_type: 'SCHEDULE_START_END',
                        scheduled_budget: 0,
                        search_result_enabled: false,
                        secondary_status: 'ADGROUP_STATUS_ALL',
                        share_disabled: false,
                        skip_learning_phase: false,
                        spending_power: 'ALL',
                        store_id: this.selected_item.store_id,
                        store_authorized_bc_id:
                            this.selected_item.store_authorized_bc_id,
                        shopping_ads_type: this.selected_item.shopping_ads_type,
                        video_download_disabled: false,
                        identity_type: this.selected_item.identity_type,
                        identity_authorized_bc_id:
                            this.selected_item.identity_authorized_bc_id,
                        campaign_type: 'PRODUCT_SALES',
                        belong_to_atosa: this.belong_to_atosa,
                        advertiser_id: this.advertiser_id,
                        creative: {
                            creatives
                        },
                        ...extend_ad_group
                    }
                })

                const temp = {
                    campaign_name,
                    budget: +this.campaign_budget,
                    budget_mode: 'BUDGET_MODE_INFINITE',
                    objective_type: 'PRODUCT_SALES',
                    switch_split_test: false,
                    switch_camp_budget_optimization: false,
                    switch_set_camp_budget: false,
                    belong_to_atosa: true,
                    advertiser_id: this.advertiser_id,
                    campaign_product_source: 'STORE',
                    adgroups
                }

                const response = await createFullCampaign(
                    this.tiktok_account_id,
                    temp
                )

                if (response.code === 0) {
                    has_success = true
                }
            } catch (error) {
                console.error(error)

                this.p__showNotify(
                    'error',
                    this.$t('message.xxx_fail', {
                        xxx: `${this.$t(`button.create`)} ${this.$t(
                            `common.campaign`
                        )}`
                    })
                )
            }
            this.m__loading = false

            if (has_success) {
                this.p__showNotify(
                    'success',
                    this.$t('message.xxx_success', {
                        xxx: `${this.$t(`button.create`)} ${this.$t(
                            `common.campaign`
                        )}`
                    })
                )

                setTimeout(() => {
                    this.show_create_success_dialog = true
                }, 112)
            }
        }
    }
}
</script>
