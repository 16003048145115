<template>
    <div class="component-box">
        <p class="font-semibold">
            {{ $t('common.advertising_objective') }}
        </p>
        <div class="mt-6 flex space-x-8">
            <div
                v-for="(item, index) in p__list_ads_objectives"
                :key="index"
                class="flex-1"
            >
                <p class="component-text-desc mb-6">
                    {{ item.title }}
                </p>

                <div
                    v-for="(i, idx) in item.items"
                    :key="idx"
                    class="mt-4 rounded-lg py-6 px-8 space-x-4 cursor-pointer border-[1px] box-border select-none flex"
                    :class="[
                        objective_type_inline === i.value
                            ? 'border-brand-atosa bg-white'
                            : 'border-transparent bg-bg hover:bg-border'
                    ]"
                    @dblclick="handleSelectObjectiveType(i.value)"
                    @click="objective_type_inline = i.value"
                >
                    <div class="min-w-10">
                        <font-awesome-icon size="lg" :icon="i.icon" />
                    </div>

                    <div>
                        <p class="font-semibold text-base mb-1">
                            {{ i.title }}
                        </p>

                        <p class="component-text-desc min-h-[44px]">
                            {{ i.description }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['objective_type', 'handle_next_step', 'belong_to_atosa'],

    computed: {
        objective_type_inline: {
            get() {
                return this.objective_type
            },
            set(value) {
                this.$emit('update:objective_type', value)
            }
        }
    },

    methods: {
        handleSelectObjectiveType(val) {
            this.objective_type_inline = val
            this.handle_next_step()
        }
    }
}
</script>
