<template>
    <custom-drawer
        size="1000px"
        :wrapper_closable="false"
        :confirm_to_close="false"
        :visible.sync="visible_in_line"
    >
        <template slot="title">
            <div class="flex items-center h-full pl-4 pr-8 space-x-4">
                <p class="font-semibold">
                    {{ $t('page.campaign.tiktok_post') }}
                </p>

                <div class="!ml-auto"></div>

                <el-button
                    icon="el-icon-refresh"
                    size="small"
                    @click="handleRefresh"
                ></el-button>

                <el-button
                    icon="el-icon-check"
                    type="primary"
                    size="small"
                    :disabled="multiple_selection.length === 0"
                    @click="handleChooseVideoForAd"
                >
                    {{ $t('button.confirm') }}
                </el-button>
            </div>
        </template>

        <div
            v-loading="m__loading"
            class="h-full pt-4 pb-8 pl-[84px] pr-12 overflow-auto bg-white"
        >
            <div class="flex space-x-2">
                <label-outside-input-select :label="$t('common.title')">
                    <el-input
                        v-model="search_title"
                        type="text"
                        size="small"
                        class="w-[240px]"
                        :clearable="true"
                        :placeholder="$t('input.placeholder.please_input')"
                        @clear="findSpuId"
                    >
                        <el-button
                            slot="append"
                            icon="el-icon-search"
                            @click="findSpuId"
                        ></el-button>
                    </el-input>
                </label-outside-input-select>

                <label-outside-input-select
                    :label="$t('page.campaign.view_mode')"
                >
                    <el-select
                        v-model="filter_status"
                        size="small"
                        placeholder="Select"
                        @change="findSpuId"
                    >
                        <el-option
                            v-for="item in status_options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </label-outside-input-select>
            </div>

            <div v-if="data_list.length" class="flex flex-wrap gap-4 mt-4">
                <div v-for="item in data_list" :key="item.item_id">
                    <div
                        class="w-[180px] relative h-[180px] group"
                        :class="[
                            handleHasChoosen(item.item_id)
                                ? ''
                                : 'hover:opacity-80'
                        ]"
                    >
                        <div
                            class="w-auto h-full rounded-lg cursor-pointer relative border-[1px]"
                            :class="[
                                handleHasChoosen(item.item_id)
                                    ? 'border-brand-atosa'
                                    : 'border-transparent bg-bg'
                            ]"
                            @click="handleChooseVideo(item)"
                        >
                            <div
                                v-if="handleHasChoosen(item.item_id)"
                                class="bg-brand-atosa opacity-20 absolute top-0 left-0 z-20 w-full h-full"
                            ></div>

                            <img
                                :src="item.video_info.poster_url"
                                class="object-contain w-full h-full"
                                alt=""
                            />

                            <div class="top-1 right-2 absolute">
                                <el-checkbox
                                    :value="handleHasChoosen(item.item_id)"
                                    @input="handleChooseVideo(item)"
                                ></el-checkbox>
                            </div>

                            <div
                                class="absolute bottom-2 right-2 bg-black p-[2px] rounded-xl"
                            >
                                <p class="text-xxs text-white">
                                    {{
                                        p__renderDurationToMinute(
                                            item.video_info.duration
                                        )
                                    }}
                                </p>
                            </div>
                        </div>

                        <div
                            class="top-1/2 left-1/2 w-7 h-7 group-hover:w-8 group-hover:h-8 util-flex-center absolute z-40 -translate-x-1/2 -translate-y-1/2 bg-black rounded-full cursor-pointer"
                            @click="
                                handleChooseVideoToShow(item.video_info.url)
                            "
                        >
                            <i
                                class="el-icon-caret-right text-xl text-white"
                            ></i>
                        </div>
                    </div>

                    <p class="text-xs truncate w-[180px]">
                        {{ item.text }}
                    </p>
                </div>
            </div>

            <div v-else class="util-flex-center mt-14 flex-col">
                <img
                    class="w-[140px] h-[140px]"
                    :src="require('@/assets/images/no_data.png')"
                />
                <p class="text-desc-text mt-4 text-sm">
                    {{ $t('common.no_data') }}
                </p>
            </div>
        </div>

        <InfoVideoDialog
            :visible.sync="show_info_video_dialog"
            :preview_url="preview_url"
        ></InfoVideoDialog>
    </custom-drawer>
</template>

<script>
import paginationDataMixin from '@/plugins/mixins/pagination-data'
import { getVideoFromIdentity } from '@/services/atosa-tiktok-ads/identity'

export default {
    mixins: [paginationDataMixin],

    props: [
        'visible',
        'advertiser_id',
        'tiktok_account_id',
        'belong_to_atosa',
        'identity_id',
        'identity_authorized_bc_id',
        'identity_type'
    ],

    data() {
        return {
            show_info_video_dialog: false,
            has_more: false,
            multiple_selection: [],
            preview_url: '',
            search_title: '',
            data_list: [],
            filter_status: 'ALL',
            status_options: [
                {
                    value: 'ALL',
                    label: this.$t('common.all')
                },
                {
                    value: 'ITEM_STATUS_HESITATE_RECOMMEND',
                    label: this.$t(
                        'page.campaign.campaign_status_tiktok_post_hesitate_recommend'
                    )
                },
                {
                    value: 'STATUS_ONLY_FRIEND_SEE',
                    label: this.$t(
                        'page.campaign.campaign_status_tiktok_post_only_friend_see'
                    )
                },
                {
                    value: 'ITEM_STATUS_ONLY_AUTHOR_SEE',
                    label: this.$t(
                        'page.campaign.campaign_status_tiktok_post_only_author_see'
                    )
                }
            ]
        }
    },

    computed: {
        visible_in_line: {
            get: function () {
                return this.visible
            },
            set: function (val) {
                this.$emit('update:visible', val)
            }
        }
    },

    watch: {
        identity_id() {
            this.fetchVideos(false)
        },

        visible_in_line() {
            if (this.visible_in_line) {
                this.multiple_selection = []
                this.fetchVideos(false)
            }
        }
    },

    mounted() {
        this.fetchVideos(false)
    },

    methods: {
        findSpuId() {
            this.data_list = this.m__data_for_pagination

            if (this.search_title) {
                const temp_search = this.search_title.trim()

                this.data_list = this.m__data_for_pagination.filter((item) =>
                    item.text.match(new RegExp(temp_search, 'i'))
                )
            }

            if (this.filter_status !== 'ALL') {
                this.data_list = this.m__data_for_pagination.filter(
                    (item) => item.status === this.filter_status
                )
            }
        },

        handleChooseVideoToShow(url) {
            this.preview_url = url
            this.show_info_video_dialog = true
        },

        async fetchVideos() {
            if (
                !this.identity_id ||
                !this.identity_authorized_bc_id ||
                !this.identity_type ||
                this.m__loading
            ) {
                return
            }

            this.m__loading = true

            let has_more = true
            let cursor = '0'

            try {
                const temp = []
                const response = await getVideoFromIdentity(
                    this.tiktok_account_id,
                    {
                        belong_to_atosa: this.belong_to_atosa,
                        advertiser_id: this.advertiser_id,
                        identity_id: this.identity_id,
                        identity_authorized_bc_id:
                            this.identity_authorized_bc_id,
                        identity_type: this.identity_type,
                        cursor
                    }
                )

                has_more = response.data.has_more
                cursor = response.data.cursor

                temp.push(...response.data.video_list)

                while (has_more) {
                    const response = await getVideoFromIdentity(
                        this.tiktok_account_id,
                        {
                            belong_to_atosa: this.belong_to_atosa,
                            advertiser_id: this.advertiser_id,
                            identity_id: this.identity_id,
                            identity_authorized_bc_id:
                                this.identity_authorized_bc_id,
                            identity_type: this.identity_type,
                            cursor
                        }
                    )

                    has_more = response.data.has_more
                    cursor = response.data.cursor

                    temp.push(...response.data.video_list)
                }

                this.m__data_for_pagination = temp

                this.findSpuId()
            } catch (error) {
                console.error(error)
            }

            this.m__loading = false
        },

        handleChooseVideo(video) {
            if (
                this.multiple_selection.some(
                    (item) => item.item_id === video.item_id
                )
            ) {
                this.multiple_selection = this.multiple_selection.filter(
                    (item) => item.item_id !== video.item_id
                )
            } else {
                this.multiple_selection = [...this.multiple_selection, video]
            }
        },

        handleRefresh() {
            this.has_more = false
            this.multiple_selection = []

            this.fetchVideos(false)
        },

        handleHasChoosen(item_id) {
            return this.multiple_selection.some(
                (item) => item.item_id === item_id
            )
        },

        handleChooseVideoForAd() {
            if (!this.multiple_selection.length) {
                return
            }

            this.$emit('choose_video_from_tiktok_post', this.multiple_selection)

            this.multiple_selection = []

            this.visible_in_line = false
        },

        handleScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
            if (this.m__loading) {
                return
            }

            if (scrollTop + clientHeight >= scrollHeight) {
                if (this.has_more && this.cursor !== '0') {
                    this.fetchVideos(true)
                }
            }
        }
    }
}
</script>
